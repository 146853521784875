<template>
  <div>
    <v-row>
      <v-col col="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9">
            <v-toolbar-title>Üyeler</v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()" />
            </div>
          </v-toolbar>
          <CCardBody class="p-0">
            <!-- :sendToClipboard="sendToClipboard" -->
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"></ag-grid-vue>
            <!-- :getContextMenuItems="getGridContextMenuItems" -->
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <CModal
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="userModalAddUpdate.modal">
      <template #header class="w-100">
        <div style="display: flex; flex-direction: column; width: 100%">
          <h1>{{ userModalAddUpdate.title }}</h1>
          <button
            type="button"
            aria-label="Close"
            class="close mb-1"
            @click="userModalAddUpdate.modal = false">
            ×
          </button>
          <div
            v-if="userModalAddUpdate.process == 'put'"
            class="d-flex justify-content-between"
            style="width: 100%">
            <div class="d-flex w-100">
              <RoleProvider slug="ADMIN_NOTE_FIND">
                <div slot-scope="{ canItPass }" class="w-100">
                  <AgentNote
                    :_id="userModalAddUpdate.form._id"
                    :slug="'members'"
                    :total="agentNotesTotal"
                    v-if="canItPass" />
                </div>
              </RoleProvider>
            </div>
          </div>
        </div>
      </template>
      <CCard>
        <CCardHeader component="h5" class="d-flex justify-content-between">
          <div class="font-weight-bold">Üye Bilgileri</div>
        </CCardHeader>
        <CCardBody class="card-body-color">
          <CCardText>
            <CRow>
              <CCol md="6">
                <h4>Kullanıcı Adı Soyadı:</h4>
                {{
                  userModalAddUpdate.form.name +
                  " " +
                  userModalAddUpdate.form.lastname
                }}
              </CCol>

              <CCol md="6">
                <h4>E-Posta:</h4>
                {{ userModalAddUpdate.form.email }}
              </CCol>

              <CCol md="6">
                <h4>Cep Telefonu:</h4>
                {{ userModalAddUpdate.form.phone }}
              </CCol>

              <CCol md="6">
                <h4>Doğum Tarihi:</h4>
                {{ userModalAddUpdate.form.birthday }}
              </CCol>

              <CCol md="6">
                <h4>
                  {{
                    userModalAddUpdate.form.tcId
                      ? "TC Kimlik No:"
                      : "Pasaport No:"
                  }}
                </h4>
                {{
                  userModalAddUpdate.form.tcId
                    ? userModalAddUpdate.form.tcId
                    : userModalAddUpdate.form.passportId
                }}
              </CCol>
              <CCol md="6">
                <h4>Talep Tarihi:</h4>
                {{ userModalAddUpdate.form.deleted }}
              </CCol>
              <CCol md="6">
                <h4>Talep Nedeni:</h4>
                {{ userModalAddUpdate?.form?.deleteReason?.tr }}
              </CCol>
            </CRow>
          </CCardText>
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader component="h5" class="d-flex justify-content-between">
          <div class="font-weight-bold">İşlem Kayıtları</div>
        </CCardHeader>
        <CCardBody class="card-body-color">
          <CCardText>
            <CRow>
              <CCol
                md="12"
                v-for="(
                  log, i
                ) in userModalAddUpdate?.form?.agentWhoDeleted?.reverse()">
                <b>
                  {{ log.name }}
                  <small>{{
                    moment(log.dateTime).local().format("DD/MM/YYYY HH:mm")
                  }}</small></b
                >
                <p>
                  {{ log.message }}
                </p>
              </CCol>
            </CRow>
          </CCardText>
        </CCardBody>
      </CCard>

      <template #footer>
        <RoleProvider slug="USER_UPDATE">
          <div class="w-100" slot-scope="{ canItPass }">
            <CButton
              style="background-color: #ff0000; color: white"
              size="lg"
              block
              v-if="
                userModalAddUpdate.process == 'put' &&
                canItPass &&
                userModalAddUpdate.form.status == 'Passive'
              "
              :disabled="userModalAddUpdate.btnDisable"
              @click="userChangeStatus(userModalAddUpdate.form._id, 'Delete')"
              >Hesabı Sil</CButton
            >

            <CButton
              color="success"
              size="lg"
              block
              v-if="
                userModalAddUpdate.process == 'put' &&
                canItPass &&
                userModalAddUpdate.form.status == 'Passive'
              "
              :disabled="userModalAddUpdate.btnDisable"
              @click="userChangeStatus(userModalAddUpdate.form._id, 'Active')"
              >Tekrar Aktifleştir</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import TelAllCountries from "@/assets/js/all-countries";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"; //h ttps://chronotruck.github.io/vue-ctk-date-time-picker/
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"; //https://chronotruck.github.io/vue-ctk-date-time-picker/
import moment from "moment";
import vSelect from "vue-select";
import AgentNote from "@/components/AgentNote";
import { IMaskComponent } from "vue-imask";
import { cibLgtm } from "@coreui/icons";
import { RoleProvider } from "../../provider";
var _ = require("lodash");
Vue.use(VueSweetalert2);
const { VUE_APP_API_URL } = process.env;
// ? Css.
import "vue-select/dist/vue-select.css";
export default {
  name: "Users",
  components: {
    AgGridVue,
    vSelect,
    AgentNote,
    VueCtkDateTimePicker,
    "imask-input": IMaskComponent,
    RoleProvider,
    VueTelInput,
  },
  data() {
    return {
      couponsName: {
        name: "Kupon Seçin",
        items: [],
      },
      couponsNumber: "",
      whereDidYouHearJSON: [
        "Instagram",
        "Twitter",
        "Facebook",
        "Google/Arama Motoru",
        "Youtube",
        "Broşür-Katalog-Insert",
        "Fuar/Etkinlik/Stand Faaliyetinden",
        "Radyo",
        "TV Reklamı",
        "Euro Kanallar / Bant Yayınları",
        "Referans/Tavsiye",
        "Açıkhava Reklamı",
        "Bilinmiyor/Hatırlamıyor",
        "RentiCar Çalışanı",
        "İş Birliği",
      ],
      couponsJSON: [],
      couponsData: [],
      phoneUpdate: {
        modal: false,
        newPhone: null,
        newPhoneInput: null,
      },
      couponModalShow: false,
      invoiceTypes: [
        {
          value: "individual",
          label: "Bireysel",
        },
        {
          value: "individualCompany",
          label: "Şahış Şirketi",
        },
        {
          value: "company",
          label: "Kurumsal",
        },
        {
          value: "abroad",
          label: "Yabancı",
        },
      ],
      searchFilterInput: "",
      agentNotesTotal: 0,
      options: [
        {
          value: 0,
          text: "Angular",
        },
        {
          value: 1,
          text: "Bootstrap",
        },
        {
          value: 2,
          text: "React.js",
        },
        {
          value: 3,
          text: "Vue.js",
        },
        {
          label: "backend",
          options: [
            {
              value: 4,
              text: "Django",
            },
            {
              value: 5,
              text: "Laravel",
            },
            {
              value: 6,
              text: "Node.js",
            },
          ],
        },
      ],
      userEmailToken: "",
      userPhoneToken: "",
      // GENEL
      func: Func,
      settings: null,

      // AG GRID
      columnDefs: [
        // Grid sütünların dizisi
        {
          field: "status",
          cellRenderer: (params) => {
            if (params.value === "Passive") return "Silme Talebi";
            else if (params.value === "Delete") return "Silindi";
            else if (params.value === "Active") return "Tekrar Aktif";
            else return "Tanımsız";
          },
          cellStyle: (params) => {
            if (params.value === "Active")
              return {
                color: "#2eb85c",
                fontWeight: "bold",
              };
            else if (params.value === "InActive" || params.value === "Passive")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
            else if (params.value === "Pending")
              return {
                color: "#f58d42",
                fontWeight: "bold",
              };
            else if (params.value === "Delete")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
          },
          headerName: "Durum",
          width: 100,
          suppressSizeToFit: true,
        },

        {
          field: "deleted",
          headerName: "Talep Tarihi",
          cellRenderer: (params) => {
            return moment(params.value).local().format("DD.MM.YYYY HH:mm");
          },
        },
        {
          field: "name",
          headerName: "Ad",
        },
        {
          field: "lastname",
          headerName: "Soyad",
        },
        {
          field: "email",
          headerName: "E-Posta",
        },
        {
          field: "phone",
          headerName: "Telefon",
        },
        {
          field: "birthday",
          headerName: "Doğum Tarihi",
          cellRenderer: (params) => {
            if (params.value) return moment(params.value).format("DD.MM.YYYY");
          },
        },
        {
          field: "registered",
          headerName: "Kayıt Tarihi",
          width: 160,
        },
        {
          field: "defaultIdType",
          headerName: "Uyruk",
          cellRenderer: (params) => {
            if (params.value === 0) return "Türk Vatandaşı";
            else return "Yabancı Uyruk";
          },
        },
        {
          field: "permissions",
          headerName: "Arama İzni",
          cellRenderer: (params) => {
            return params.value.includes("phone") ? "ONAY" : "RED";
          },
        },
        {
          field: "permissions",
          headerName: "SMS İzni",
          cellRenderer: (params) => {
            return params.value.includes("sms") ? "ONAY" : "RED";
          },
        },
        {
          field: "permissions",
          headerName: "Email İzni",
          cellRenderer: (params) => {
            return params.value.includes("email") ? "ONAY" : "RED";
          },
        },
        {
          field: "creator",
          headerName: "Üye Yapan",
          cellRenderer: (params) => {
            if (params.value) return "Agent";
            return "User";
          },
        },
        {
          field: "creatorName",
          headerName: "Üyeyi Yapan Kişi",

          width: 220,
        },
        {
          field: "next4bizPersonId",
          headerName: "Next4Bix ID",
        },
        {
          field: "whereDidYouHear",
          headerName: "Bizi Nereden Duydunuz",
        },
        {
          field: "totalReservation",
          headerName: "Toplam Kiralama",
        },
        {
          field: "totalReservationPrice",
          headerName: "Toplam Ödeme",
        },
        {
          field: "totalReservationRefundPrice",
          headerName: "Toplam İade",
        },
      ],
      defaultColDef: {
        // Varsayılan bir sütun tanımı
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [], // Verilerin ekleneceği dizi
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak bileşenler
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },

      // USER EKLEME GÜNCELLEME MODAL
      userModalAddUpdate: {
        modal: false,
        title: "",
        process: "",
        btnDisable: false,
        fields: {
          name: {
            options: {
              min: 2,
              max: 75,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          lastname: {
            options: {
              min: 2,
              max: 50,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          email: {
            options: {
              min: 5,
              max: 50,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          phone: {
            options: {
              allCountries: TelAllCountries, //
              autoFormat: true, //
              customValidate: false, //
              defaultCountry: "tr", //
              disabled: false, //
              autoDefaultCountry: true, //
              ignoredCountries: [], //
              errorMessage: "", //
              mode: "national", //
              onlyCountries: [], //
              preferredCountries: [],
              styleClasses: "", //
              validCharactersOnly: true,

              label: "Cep Telefonu",
              labelBold: true,
              min: 17,
              max: 17,
              shake: false,
            },
            inputOptions: {
              autocomplete: "on",
              autofocus: false,
              "aria-describedby": "",
              id: "",
              maxlength: 17,
              name: "telephone",
              placeholder: "",
              readonly: false,
              required: true,
              tabindex: 0,
              type: "tel",
              styleClasses: "",
            },
            dropdownOptions: {
              disabled: false,
              showDialCodeInList: true,
              showDialCodeInSelection: false,
              showFlags: true,
              tabindex: 0,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          birthday: {
            options: {
              minDate: null,
              maxDate: null,
            },
          },
          defaultIdType: {
            options: [
              {
                value: 0,
                label: "TC Kimlik",
              },
              {
                value: 1,
                label: "Pasaport",
              },
            ],
          },
          tcId: {
            options: {
              min: 11,
              max: 11,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passportId: {
            options: {
              min: 7,
              max: 9,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passwordAgain: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          status: {
            options: [
              {
                value: "Active",
                label: "Aktif",
              },
              {
                value: "InActive",
                label: "Pasif",
              },
              {
                value: "Pending",
                label: "Bekliyor",
              },
              {
                value: "Banned",
                label: "Engellendi",
              },
            ],
          },
          lang: {
            options: [
              {
                value: "tr",
                label: "Türkçe",
              },
              {
                value: "en",
                label: "İngilizce",
              },
            ],
          },
          permissions: {
            options: [
              {
                value: "sms",
                label: "SMS",
              },
              {
                value: "phone",
                label: "Telefon",
              },
              {
                value: "email",
                label: "E-Posta",
              },
            ],
          },
        },
        form: {
          _id: "",
          name: "",
          lastname: "",
          email: "",
          deleted: "",
          phone: "",
          phoneInput: "",
          phone: "",
          birthday: "",
          defaultIdType: 0,
          tcId: "",
          passportId: "",
          permissions: ["sms", "phone", "email"],

          checkboxTI: 1,

          invoices: {
            invoiceType: "individual",
            individual: {
              province: "",
              postCode: "",
              address: "",
            },
            individualCompany: {
              firstname: "",
              lastname: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            company: {
              title: "",
              taxOffice: "",
              taxNumber: "",
              province: "",
              postCode: "",
              address: "",
            },
            abroad: {
              title: "",
              firstname: "",
              lastname: "",
              passportNumber: "",
              taxNumber: "",
              country: "",
              province: "",
              postCode: "",
              address: "",
            },
          },
          whereDidYouHear: "",
        },
      },

      // USER ŞİFRE GÜNCELLEME MODAL
      userModalPasswordUpdate: {
        modal: false,
        title: "",
        btnDisable: false,
        fields: {
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          passwordAgain: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
        },
        form: {
          _id: "",
          password: "",
          passwordAgain: "",
        },
      },
    };
  },
  async created() {
    this.$Progress.start();

    await this.getUsers();
    await this.getSettings();
    this.$Progress.finish();
  },
  watch: {
    "userModalAddUpdate.form.permissions": function (value) {
      if (value.length == 0) {
        this.userModalAddUpdate.form.consent = false;
      } else {
        this.userModalAddUpdate.form.consent = true;
      }
    },
    "userModalAddUpdate.form.defaultIdType": function (value) {
      if (value) {
        this.userModalAddUpdate.fields.tcId.valid.value = null;
        this.userModalAddUpdate.fields.tcId.valid.text = "";
        this.userModalAddUpdate.fields.passportId.valid.value = null;
        this.userModalAddUpdate.fields.passportId.valid.text = "";
      }
    },
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    userChangeStatus(id, status) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu statüsünü " + status + " çekmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              var params = {
                id,
                status,
              };
              await axios.put(
                process.env.VUE_APP_API_URL + "admin/users/status-update/",
                params,
                {
                  withCredentials: true,
                }
              );
              this.successSwal("Kullanıcının Statüsü değiştirildi");
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      //params.api.sizeColumnsToFit();
    },

    onRowDoubleClicked(params) {
      const birthday = new Date(params.node.data.birthday);
      var dd = String(birthday.getDate()).padStart(2, "0");
      var mm = String(birthday.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = birthday.getFullYear();

      this.userModalAddUpdate.process = "put";
      this.userModalAddUpdate.title = "Üye Düzenle";

      this.userModalAddUpdate.form._id = params.node.data._id;
      this.userModalAddUpdate.form.whereDidYouHear =
        params.node.data.whereDidYouHear;
      this.userModalAddUpdate.form.name = params.node.data?.name;
      this.userModalAddUpdate.form.lastname = params.node.data.lastname;
      this.userModalAddUpdate.form.deleteReason = params.node.data.deleteReason;

      this.userModalAddUpdate.form.status = params.node.data.status;

      this.userModalAddUpdate.form.agentWhoDeleted =
        params.node.data.agentWhoDeleted;

      this.userModalAddUpdate.form.email = params.node.data.email;
      this.userModalAddUpdate.form.phone = params.node.data.phone;
      this.userModalAddUpdate.form.birthday = dd + "/" + mm + "/" + yyyy;
      this.userModalAddUpdate.form.defaultIdType =
        params.node.data.defaultIdType;
      this.userModalAddUpdate.form.tcId = params.node.data.tcId;
      this.userModalAddUpdate.form.passportId = params.node.data.passportId;
      this.userModalAddUpdate.form.deleted = moment(params.node.data.deleted)
        .local()
        .format("DD/MM/YYYY HH:mm");

      if (params.node.data.defaultIdType === 0) {
        this.userModalAddUpdate.form.tcId = params.node.data.tcId;
        this.userModalAddUpdate.form.passportId = "";
      } else {
        this.userModalAddUpdate.form.tcId = "";
        this.userModalAddUpdate.form.passportId = params.node.data.passportId;
      }
      if (params.node.data.invoices) {
        this.userModalAddUpdate.form.invoices.invoiceType =
          params.node.data.invoices.invoiceType;

        this.userModalAddUpdate.form.invoices.individual.province =
          params.node.data.invoices.individual.province;
        this.userModalAddUpdate.form.invoices.individual.postCode =
          params.node.data.invoices.individual.postCode;
        this.userModalAddUpdate.form.invoices.individual.address =
          params.node.data.invoices.individual.address;

        this.userModalAddUpdate.form.invoices.individualCompany.firstname =
          params.node.data.invoices.individualCompany.firstname;
        this.userModalAddUpdate.form.invoices.individualCompany.lastname =
          params.node.data.invoices.individualCompany.lastname;
        this.userModalAddUpdate.form.invoices.individualCompany.taxOffice =
          params.node.data.invoices.individualCompany.taxOffice;
        this.userModalAddUpdate.form.invoices.individualCompany.taxNumber =
          params.node.data.invoices.individualCompany.taxNumber;
        this.userModalAddUpdate.form.invoices.individualCompany.province =
          params.node.data.invoices.individualCompany.province;
        this.userModalAddUpdate.form.invoices.individualCompany.postCode =
          params.node.data.invoices.individualCompany.postCode;
        this.userModalAddUpdate.form.invoices.individualCompany.address =
          params.node.data.invoices.individualCompany.address;

        this.userModalAddUpdate.form.invoices.company.title =
          params.node.data.invoices.company.title;
        this.userModalAddUpdate.form.invoices.company.taxOffice =
          params.node.data.invoices.company.taxOffice;
        this.userModalAddUpdate.form.invoices.company.taxNumber =
          params.node.data.invoices.company.taxNumber;
        this.userModalAddUpdate.form.invoices.company.province =
          params.node.data.invoices.company.province;
        this.userModalAddUpdate.form.invoices.company.postCode =
          params.node.data.invoices.company.postCode;
        this.userModalAddUpdate.form.invoices.company.address =
          params.node.data.invoices.company.address;

        this.userModalAddUpdate.form.invoices.abroad.title =
          params.node.data.invoices.abroad.title;
        this.userModalAddUpdate.form.invoices.abroad.firstname =
          params.node.data.invoices.abroad.firstname;
        this.userModalAddUpdate.form.invoices.abroad.lastname =
          params.node.data.invoices.abroad.lastname;
        this.userModalAddUpdate.form.invoices.abroad.passportNumber =
          params.node.data.invoices.abroad.passportNumber;
        this.userModalAddUpdate.form.invoices.abroad.taxNumber =
          params.node.data.invoices.abroad.taxNumber;
        this.userModalAddUpdate.form.invoices.abroad.country =
          params.node.data.invoices.abroad.country;
        this.userModalAddUpdate.form.invoices.abroad.province =
          params.node.data.invoices.abroad.province;
        this.userModalAddUpdate.form.invoices.abroad.postCode =
          params.node.data.invoices.abroad.postCode;
        this.userModalAddUpdate.form.invoices.abroad.address =
          params.node.data.invoices.abroad.address;
      }

      this.userModalAddUpdate.form.permissions = params.node.data.permissions;

      this.userModalAddUpdate.modal = true;
      this.getAgentNotesTotal(params.node.data._id);
      this.couponsUserGet(params.node.data._id);
    },

    async getSettings() {
      let response = await axios.get(
        process.env.VUE_APP_API_URL + "admin/settings"
      );
      this.settings = response.data.settings;
    },

    async getUsers() {
      let response = await axios.get(
        process.env.VUE_APP_API_URL + "admin/user-delete-requests"
      );
      let userList = response.data.userlist;

      userList.forEach((user) => {
        user.registered = moment(user.registered)
          .local()
          .format("DD MMM YYYY - HH:mm:ss");
        user.creatorName = user?.creator
          ? user.creator?.name + " " + user.creator.lastname
          : "";
        user.totalReservationPrice = 0;
        user.totalReservationRefundPrice = 0;
        user?.totalReservation.map((pay) => {
          pay.payments.map((item) => {
            item.paymentType == "payment" &&
              (user.totalReservationPrice += item.totalAmount);
            item.paymentType == "refund" &&
              (user.totalReservationRefundPrice += item.totalAmount);
          });
        });
        function financial(x) {
          return Number.parseFloat(x).toFixed(2) + " TL";
        }

        user.totalReservation = user.totalReservation.length;
        user.totalReservationPrice = financial(user?.totalReservationPrice);
        user.totalReservationRefundPrice = financial(
          user.totalReservationRefundPrice
        );
      });
      this.rowData = userList;
    },

    successSwal(text, refresh = true) {
      let timerInterval;
      Vue.swal
        .fire({
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        .then((result) => {
          if (result.dismiss === Vue.swal.DismissReason.timer) {
            refresh ? location.reload() : "";
          }
        });
    },

    async getAgentNotesTotal(id) {
      try {
        let agentNotes = await axios.get(
          process.env.VUE_APP_API_URL + "admin/note?place._id=" + id,
          {
            withCredentials: true,
          }
        );
        this.agentNotesTotal = agentNotes.data.length;
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss"></style>
